import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.breakPoints.references}) {
    padding: 0 30px;
  }
`

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
`

const Label = styled.span`
  display: block;
  position: absolute;
  top: calc(100% + 20px);
  text-align: center;
  font-size: ${props => props.theme.fontSizes.mobile};
  width: 200px;
`

const Icon = ({ src, label }) => (
  <IconWrapper>
    <img src={src} alt={label} />
    <Label>{label}</Label>
  </IconWrapper>
)

const References = props => {
  return (
    <Wrapper {...props}>
      <Icon
        src="/logo-uj.svg"
        label="Uniwersytet Jagielloński"
      />
    </Wrapper>
  )
}

export default References
