import React from "react"

import SEO from "../components/seo"
import MainLayout from "../layouts/main-layout"
import AboutSection from "../components/about-section"

const AboutPage = ({ data }) => {
  const images = data.allFile.edges.reduce((acc, { node }) => {
    acc[node.name] = node.childImageSharp.fluid

    return acc
  }, {})

  return (
    <MainLayout>
      <SEO title="O mnie" />
      <AboutSection avatar={images["avatar"]} image={images["about-me"]} />
    </MainLayout>
  )
}

export const query = graphql`
  query AboutPage {
    allFile(filter: { extension: { regex: "/(jpg)/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              aspectRatio
              base64
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`

export default AboutPage
