import React from "react"
import SectionWrapper from "./section-wrapper"
import Avatar from "./avatar"
import References from "./references"
import styled from "styled-components"
import Image from "gatsby-image"

const StyledWrapper = styled(SectionWrapper)`
  margin: 30px auto;
`
const StyledReferences = styled(References)`
  margin: 30px auto;
`

const StyledAvatar = styled(Avatar)`
  margin: 30px auto;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    display: none;
  }
`

const StyledImage = styled(Image)`
  display: none;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    display: block;
    width: 40%;
    max-width: 400px;
    object-fit: contain;
  }
`

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Content = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    margin-left: 30px;
    width: 60%;
  }
`

const AboutSection = ({ avatar, image }) => (
  <StyledWrapper>
    <Grid>
      <StyledImage fluid={image} />
      <Content>
        <StyledAvatar image={avatar} />
        <p>
          Jestem <b>psychologiem</b> (5 letnie jednolite studia na Uniwersytecie
          Jagiellońskim) oraz <b>psychoterapeutą</b> (ukończyłam Krakowskie
          Centrum Psychodynamiczne), doświadczenie zdobywałam pracując z
          dziećmi (diagnoza, wspomaganie rozwoju), młodzieżą, osobami dorosłymi
          w kryzysie, na oddziałach szpitalnych oraz w ośrodku psychoterapii
          rodzin w Krakowie.
        </p>
        <p>
          Oferuję psychoterapię oraz konsultacje psychologiczne dla osób
          dorosłych oraz młodzieży. Pracuję opierając się głównie na założeniach
          nurtu psychodynamicznego. Na co dzień współpracuję ze specjalistami z
          dziedziny psychiatrii, psychoterapii oraz diagnozy psychologicznej, a
          swoją pracę poddaję superwizji.
        </p>
        <p>Pracuję zarówno w języku polskim jak i angielskim.</p>
        <StyledReferences />
      </Content>
    </Grid>
  </StyledWrapper>
)

export default AboutSection
